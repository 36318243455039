.App {
  min-height: 100vh;
  min-width: 100vw;
  font-size: calc(10px + 1vmin);
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
}

p {
  padding: 0;
  margin: 0;
}

a {
  all: unset;
  color: inherit;
  cursor: pointer;
}

.App-logo {}

@media (prefers-reduced-motion: no-preference) {}

.App-header {}

.App-link {}

body {
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
